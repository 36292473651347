.app {
  display: flex;
  flex-flow: column;
}

.navbar {
}

.main-content {
  flex: "1 1 auto";
  height: 100%;
}
