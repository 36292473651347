.focused-property-view {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.focused-property-close-button {
  align-self: flex-end;
}
.property-page {
  flex: "1 1 auto";
}
